import axios from 'axios';
import qs from 'qs';

const api = axios.create({
    baseURL: '/api',
});

export function RESTORE_ACCESS(email) {
    return new Promise((res, rej) => {
        api.post('/account/restoreAccess', qs.stringify({
            email,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            console.log(error.response);
            rej(error.response);
        });
    });
}

export function CHANGE_PASSWORD(login, oldPassword, newPassword) {
    return new Promise((res, rej) => {
        api.post('/account/changePass', qs.stringify({
            login, oldPassword, newPassword,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            console.log(error.response);
            rej(error.response);
        });
    });
}

export function AUTH_USER(login, pass) {
    return new Promise((res, rej) => {
        api.post('/users/signIn', qs.stringify({
            login, pass,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}
export function AUTH_LOGOUT() {
    return new Promise((res, rej) => {
        api.post('/users/logout').then(() => {
            res(true);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_CLIENT_INFO() {
    return new Promise((res, rej) => {
        try {
            api.get('/users/getClientInfo').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function UPLOAD_PROFILE_IMAGE(login, file) {
    const formData = new FormData();

    formData.append('login', login);
    formData.append('file', file);

    return new Promise((res, rej) => {
        try {
            api.post('/users/uploadProfileImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function GET_POLICY_LIST() {
    return new Promise((res, rej) => {
        try {
            api.get('/policySellers/getListPolicies').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function GET_POLICY(policyId) {
    return new Promise((res, rej) => {
        api.get('/policySellers/getPolicy', {
            params: {
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function PRODUCTS_LIST() {
    return new Promise((res, rej) => {
        try {
            api.get('/products/list').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function PRODUCTS_PROPOSE_PRODUCT(title, link) {
    return new Promise((res, rej) => {
        api.post('/products/proposeProduct', qs.stringify({
            title, link,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function ADD_BASKET(id, title, price, itemNumber, quantity, imageId, option) {
    return new Promise((res, rej) => {
        api.post('/basket/add', qs.stringify({
            id, title, price, itemNumber, quantity, imageId, option,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function BASKET_DELETE(id) {
    return new Promise((res, rej) => {
        api.post('/basket/delete', qs.stringify({
            id,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function BASKET_LIST() {
    return new Promise((res, rej) => {
        api.post('/basket/list').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function ORDER_LIST() {
    return new Promise((res, rej) => {
        api.post('/order/list').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function ORDER_PLACE(address, phone) {
    return new Promise((res, rej) => {
        api.post('/order/place', qs.stringify({
            address, phone,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_DATA_CHARTS(index, policyId) {
    return new Promise((res, rej) => {
        api.get('/dataCharts/getGraphicsData', {
            params: {
                index,
                policyId,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function CREATE_POLICY_REPORT(policyId, email) {
    return new Promise((res, rej) => {
        api.post('/reportPDF/execute', qs.stringify({
            policyId, email,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUPERVISOR_POLICY_LIST() {
    return new Promise((res, rej) => {
        api.get('/supervisor/getPolicyList').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUPERVISOR_ORDER_LIST() {
    return new Promise((res, rej) => {
        api.get('/supervisor/getOrderList').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function SUPERVISOR_RATING_LIST(office, territorialDirectorate, macroRegion) {
    return new Promise((res, rej) => {
        api.post('/supervisor/getRatingList', qs.stringify({
            office, territorialDirectorate, macroRegion,
        })).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_NEWS() {
    return new Promise((res, rej) => {
        try {
            api.get('/news/getNews').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function HIDE_PROMOTION_MESSAGE() {
    return new Promise((res, rej) => {
        api.post('/users/hidePromotionMessage').then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}

export function GET_RATING_LIST() {
    return new Promise((res, rej) => {
        try {
            api.get('/users/getRatingList').then((response) => {
                res(response.data);
            }).catch((error) => {
                rej(error.response);
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    });
}

export function CHECK_CAPTCHA(token) {
    return new Promise((res, rej) => {
        api.get('/captcha/checkCaptcha', {
            params: {
                token,
            },
        }).then((response) => {
            res(response.data);
        }).catch((error) => {
            rej(error.response);
        });
    });
}
